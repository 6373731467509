exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-dev-js": () => import("./../../../src/pages/dev.js" /* webpackChunkName: "component---src-pages-dev-js" */),
  "component---src-pages-guitar-js": () => import("./../../../src/pages/guitar.js" /* webpackChunkName: "component---src-pages-guitar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-service-design-js": () => import("./../../../src/pages/service-design.js" /* webpackChunkName: "component---src-pages-service-design-js" */),
  "component---src-pages-startups-js": () => import("./../../../src/pages/startups.js" /* webpackChunkName: "component---src-pages-startups-js" */),
  "component---src-pages-thoughts-js": () => import("./../../../src/pages/thoughts.js" /* webpackChunkName: "component---src-pages-thoughts-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

